<template>
    <v-card>
        <v-card-title>
            <span class="text-h6">{{ $t('labels.employee') }}</span>
            <v-spacer></v-spacer>
            <v-btn small color="success" outlined @click="showDialogAddEmployee" class="mr-2">
                {{ $t('labels.add') }}
            </v-btn>
            <v-btn small color="error" outlined @click="closeDialog">
                {{ $t('labels.close') }}
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-simple-table class="table-padding-2">
                <template v-slot:default>
                    <thead class="v-data-table-header">
                        <tr>
                            <th role="columnheader" class="" style="min-width: 115px">{{ $t('labels.employee') }}</th>
                            <th role="columnheader" class="text-center">{{ $t('labels.uid') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(emp, key) in items" :key="`d_${key}`">
                            <td>{{ emp.employee_name }}</td>
                            <td>{{ emp.uids }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>

        <v-dialog v-model="dialogAddEmployee" persistent max-width="340px">
            <FindGoodsAddEmployee v-if="dialogAddEmployee" :id-pos="item.id_pos" :id-find-goods="item.id"
                :processing-employees="items" @closeDialog="hideDialogAddEmployee" @refreshData="getDetails" />
        </v-dialog>
    </v-card>
</template>
  
<script>
import { httpClient } from "@/libs/http";
export default {
    name: "FindGoodsDetail",
    components: {
        FindGoodsAddEmployee: () => import('@/components/pos_goods/FindGoodsAddEmployee'),
    },
    props: {
        item: {
            type: Object,
            default: () => { }
        },
    },
    data: () => ({
        items: [],
        dialogAddEmployee: false,
    }),
    mounted() {
        this.getDetails()
    },
    methods: {
        closeDialog() {
            this.$emit('closeDialog')
        },
        async getDetails() {
            const { data } = await httpClient.post('/find-goods-manager-detail', {
                id_find_goods: this.item.id
            })
            this.items = [...data]
        },
        showDialogAddEmployee() {
            this.dialogAddEmployee = true
        },
        hideDialogAddEmployee() {
            this.dialogAddEmployee = false
        }
    }
}
</script>
  
<style scoped></style>
  